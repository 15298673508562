import { IncomeAction as Income } from './action';
import { investmentWithInterest } from './interest';
import { RightState, TitleState } from './state';
import { assertNode, getNode, isRight } from './node';
import {
  BlockingArrayOperator,
  BlockingNumberOperator,
  blockingNumberOperator,
  ConditionOwnerLabel,
  GroupScope,
  Media,
  Territory,
  thresholdNumberOperator,
  ThresholdNumberOperator
} from '../static';
import { Expense, expensesPrice } from '../expense';
import { getRightConditions, Right } from './right';
import { createWaterfallEvent, WaterfallEventMode } from './waterfall';

export const thresholdConditions = {
  /** Will compare current amount arriving on node with org revenue */
  orgRevenu,
  /** 
   * The amount that passed by a specific org.
   * 
   * Example: 
   *  1 - 100k comes from previous node to a right that belongs to org A.
   *  2 - org A takes 50%
   *  3 - org A revenue is 50k but turnover is 100k
   */
  orgTurnover,
  /**
   * Similar to org revenue.
   * 
   * Example:
   * Org can be seller & investor but we might want to add a condition
   * on org revenue earned by his seller activities and not the investor one
   */
  poolRevenu,
  /** Will compare current amount arriving on node with pool shadow revenue (without taking care of shadow conditions) */
  poolShadowRevenu,
  /** See poolRevenue & OrgTurnover */
  poolTurnover,
  /** Revenue earned by a specific right */
  rightRevenu,
  /** Amount that passed by a specific right */
  rightTurnover,
  /** 
   * Right will try to take a percentage of the income until a certain amount is reached.
   */
  takefromIncomeUntil,
  /**
   * Right will try to take a percentage of the source amount as long as right.revenu is lower.
   */
  takefromSourceUntil,
  /** * Revenue earned by a specific group */
  groupRevenu,
  /** Amount that passed by a specific group */
  groupTurnover,
  /**
   * Condition will match if calculated OrgRevenu is "operator (ex: less than)" than x (ex: 100%) percent of 
   * investments + interests of contractId with [conposite] interest with a rate of y (ex: 4%) 
   * Interests are calculated on each new income, investment and year.
   * Interest can be composite: interests of each period are incorporated into the capital to increase it gradually.
   * (Income and investments must have a date)
   */
  interest,
  filmAmortized
}

const blockingConditions = {
  /** Condition that will match if a event was triggered or not triggered */
  event,
  /** 
   * Condition that will match only if income comes before or after a given date 
   * 
   * If "from" is specified, date will be checked against income date to check if date is after "from"
   * If "to" is specified, date will be checked against income date to check if date is before "to"
   * 
   * @dev might be removed if "rightEnabled" condition fits all needs
   */
  incomeDate,
  /**
   * Every income should be linked to a contract.
   * This will check if contract date linked to income matches the condition or not.
   * 
   * If "from" is specified, date will be checked against contract start date to check if contract start date is after "from"
   * If "to" is specified, date will be checked against contract end date to check if contract end date is before "to"
   * 
   * @dev might be removed if "rightEnabled" condition fits all needs
   */
  contractDate,
  /** @deprecated use contractAmount instead */
  amount,
  /** Condition that will match only if income comes from specific media(s) or territory(ies) */
  terms,
  /** @deprecated not used. Might be removed in future */
  termsLength,
  /**
   * Condition on a right to take or not take income coming from a 
   * specific contract
   * @deprecated not used. Might be removed in future
   */
  contract,
  /**
   * Condition on a right to take or not take income coming from contract with a specific amount.
   * 
   * Example:
   *  - if a contract is made between distributor & seller for an amount greater than 50k
   */
  contractAmount,
  /**
   * Condition will match if incomes of a specific source are greater than x
   */
  sourceAmount,
  /**
   * This condition is implicitly added to every right with a contractId.
   * It will check if the income date is between contract start and end date.
   * If not, right is marked as disabled and turnover is not incremented for this right, org and pool.
   */
  rightEnabled
}

export const allConditions = {
  ...blockingConditions,
  ...thresholdConditions
};

export const conditionNames = Object.keys(allConditions);

export type ConditionName = keyof typeof allConditions;
export type ConditionList = {
  [key in ConditionName]: {
    name: key,
    payload: Parameters<typeof allConditions[key]>[1],
  }
};

export type ThresholdCondition = ConditionList[keyof typeof thresholdConditions];
type BlockingCondition = ConditionList[keyof typeof blockingConditions];
export type ConditionWithTarget = ConditionList[Exclude<keyof typeof thresholdConditions, 'takefromSourceUntil' | 'takefromIncomeUntil' | 'interest'>];
export type Condition = ThresholdCondition | BlockingCondition;

interface ConditionContext {
  state: TitleState;
  /** Current right on which the condition is triggered */
  right: RightState;
  /** Amount of income that arrives on the right */
  income: Income;
  /** Initial source id from where is coming the income */
  sourceId: string;
}

export function isThresholdCondition(condition: Condition): condition is ThresholdCondition {
  return Object.keys(thresholdConditions).includes(condition.name);
}

export function isCondition(condition: Condition | ConditionGroup): condition is Condition {
  return !Array.isArray(condition);
}

export function isConditionWithTarget(condition: Condition): condition is ConditionWithTarget {
  return Object.keys(thresholdConditions).filter(k => k !== 'interest').includes(condition.name);
}

export function getConditionSubject(condition: ConditionWithTarget): { in: ConditionOwnerLabel, id: string } {
  if ((condition.payload as OrgRevenuCondition).orgId) return { in: 'org', id: (condition.payload as OrgRevenuCondition).orgId };
  if ((condition.payload as PoolCondition).pool) return { in: 'pool', id: (condition.payload as PoolCondition).pool };
  if ((condition.payload as RightCondition).rightId) return { in: 'right', id: (condition.payload as RightCondition).rightId };
  if ((condition.payload as GroupCondition).groupId) return { in: 'group', id: (condition.payload as GroupCondition).groupId };
}

export type TakeFromUntilConditions = ConditionList['takefromSourceUntil' | 'takefromIncomeUntil'];
const takeFromUntilConditions: ConditionName[] = ['takefromSourceUntil', 'takefromIncomeUntil'];
export function getTakeFromUntilCondition(conditions: Condition[]) {
  return conditions.find(c => takeFromUntilConditions.includes(c.name)) as TakeFromUntilConditions;
}

export function getPoolShadowRevenuCondition(conditions: Condition[]) {
  return conditions.find(c => c.name === 'poolShadowRevenu') as ConditionList['poolShadowRevenu'];
}

export function skipTakeFromUntilConditions(conditions: Condition[]) {
  return conditions.filter(c => !takeFromUntilConditions.includes(c.name));
}

export function splitConditions(group: ConditionGroup) {
  const thresholdCdts: ThresholdCondition[] = [];
  const blockingCdts: BlockingCondition[] = [];
  for (const condition of group.conditions) {
    if (isConditionGroup(condition)) {
      const splitted = splitConditions(condition);
      thresholdCdts.push(...splitted.thresholdCdts);
      blockingCdts.push(...splitted.blockingCdts);
    } else {
      isThresholdCondition(condition)
        ? thresholdCdts.push(condition)
        : blockingCdts.push(condition);
    }
  }
  return { thresholdCdts, blockingCdts };
}

// Utils
/** Blocking operation will always return either the total amount or nothing */
function blockingOperator(operator: BlockingNumberOperator, _current: number, target: number) {
  // This handles cases where _current might be a string representation of a number
  const current = parseFloat(_current as any);
  switch (operator) {
    case '==': return current === target;
    case '!=': return current !== target;
    case '>=': return current >= target;
    case '<': return current < target;
    case '<=': return current <= target;
    case '>': return current > target;
    default: throw new Error(`Condition should have one of the operators ${blockingNumberOperator.map(o => `"${o}"`).join(', ')}, but got ${operator}`);
  }
}

function thresholdOperator(operator: ThresholdNumberOperator, current: number, target: number) {
  switch (operator) {
    case '>=': return current >= target;
    case '<': return current < target;
    default: throw new Error(`Condition should have one of the operators ${thresholdNumberOperator.map(o => `"${o}"`).join(', ')}, but got ${operator}`);
  }
}

//////////////////////
// Condition groups //
//////////////////////

export function checkCondition(ctx: ConditionContext) {
  const enabled = ctx.right.contractId ? checkGroupCondition(ctx, { operator: 'AND', conditions: [{ name: 'rightEnabled', payload: undefined }] }) : true;
  const checked = enabled && checkGroupCondition(ctx, ctx.right.conditions);
  const shadow = checked || (enabled && checkGroupCondition(ctx, ctx.right.conditions, ['poolShadowRevenu']));
  return { shadow, checked, enabled };
}

export const isConditionGroup = (condition: Condition | ConditionGroup): condition is ConditionGroup => {
  if (!condition) return false;
  return 'operator' in condition;
}

function runCondition(ctx: ConditionContext, condition: Condition) {
  const { name, payload } = condition;
  return allConditions[name](ctx, payload as any);
}

function checkGroupCondition(ctx: ConditionContext, group: ConditionGroup, excludedCondition: ConditionName[] = []): boolean {
  const { operator, conditions: allConditions } = group;
  if (!allConditions.length) return true;
  const result: boolean[] = [];
  for (const condition of allConditions) {
    if (isConditionGroup(condition)) {
      const checked = checkGroupCondition(ctx, condition, excludedCondition);
      result.push(checked);
    } else {
      // TODO: condition should return false when condition not ready...
      const checked = excludedCondition.includes(condition.name) ? true : runCondition(ctx, condition);
      result.push(checked);
    }
  }

  return operator === 'AND' ? result.every(r => r) : result.some(r => r);
}

export const condition = <N extends ConditionName>(name: N, payload: ConditionList[N]['payload']) => {
  return { name, payload };
}

export interface ConditionGroup {
  operator: 'OR' | 'AND';
  conditions: (Condition | ConditionGroup)[];
}

export function and(conditions: (Condition | ConditionGroup)[]): ConditionGroup {
  return { operator: 'AND', conditions };
}

/**
 * @deprecated not used. Might be removed in future
 * @param conditions 
 * @returns 
 */
function or(conditions: (Condition | ConditionGroup)[]): ConditionGroup {
  return { operator: 'OR', conditions };
}


///////////////
// REFERENCE //
///////////////

const isNumber = (v: unknown): v is number => typeof v === 'number';

/** @dev If other targets are enabled, add them in "targetToString" libs/waterfall/src/lib/pipes/condition-to-string.pipe.ts */
export const targetIn = ['expense', 'contracts.investment', 'amortization.filmCost'] as const;
export type TargetIn = typeof targetIn[number];
export type TargetValue = {
  id: string;
  percent: number; // Between 0 and 1
  in: TargetIn
} | number;
export function toTargetValue(state: TitleState, target: TargetValue) {
  if (isNumber(target)) return target;

  const { id, percent } = target;
  switch (target.in) {
    case 'expense': return getExpensesValue(state, id) * percent;
    case 'contracts.investment': return getContractPrice(state, id) * percent;
    case 'amortization.filmCost': return getFilmCost(state, id) * percent;
    default: throw new Error(`Target "${target.in}" not supported.`);
  }
}

export function getExpensesValue(state: TitleState, typeId: string, date?: Date, capped = true) {
  const expenses: Partial<Expense>[] = Object.values(state.expenses)
    .filter(e => e.typeId === typeId)
    .filter(e => !date || new Date(e.date).getTime() <= date.getTime())
    .map(e => ({ typeId: e.typeId, price: e.amount, capped: e.capped }));

  if (expenses.length === 0) return 0;
  if (!state.expenseTypes[typeId]) throw new Error(`Expense type "${typeId}" doesn't exist`);
  return expensesPrice(expenses, typeId, capped ? state.expenseTypes[typeId].cap : 0);
}

export function getContractPrice(state: TitleState, contractId: string) {
  if (!state.contracts[contractId]) return 0;
  return state.contracts[contractId].amount;
}

function getFilmCost(state: TitleState, amortizationId: string) {
  const amortization = state.amortizations[amortizationId];
  return amortization.filmCost;
}

export interface OrgRevenuCondition {
  orgId: string;
  target: TargetValue;
  operator: ThresholdNumberOperator;
}

function orgRevenu(ctx: ConditionContext, payload: OrgRevenuCondition) {
  const { state } = ctx;
  const { orgId, target, operator } = payload;
  const org = state.orgs[orgId];
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, org.revenu.calculated, targetValue);
}

function orgTurnover(ctx: ConditionContext, payload: OrgRevenuCondition) {
  const { state } = ctx;
  const { orgId, target, operator } = payload;
  const org = state.orgs[orgId];
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, org.turnover.calculated, targetValue);
}

export interface PoolCondition {
  pool: string;
  target: TargetValue;
  operator: ThresholdNumberOperator;
}

function poolRevenu(ctx: ConditionContext, payload: PoolCondition) {
  const { state } = ctx;
  const { target, operator, pool } = payload;
  const currentValue = state.pools[pool]?.revenu.calculated ?? 0;
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, currentValue, targetValue);
}

function poolShadowRevenu(ctx: ConditionContext, payload: PoolCondition) {
  const { state } = ctx;
  const { target, operator, pool } = payload;
  const currentValue = state.pools[pool]?.shadow.revenu ?? 0;
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, currentValue, targetValue);
}

function poolTurnover(ctx: ConditionContext, payload: PoolCondition) {
  const { state } = ctx;
  const { target, operator, pool } = payload;
  const currentValue = state.pools[pool]?.turnover.calculated ?? 0;
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, currentValue, targetValue);
}

export interface FilmAmortizedCondition {
  target: TargetValue;
  operator: ThresholdNumberOperator;
}

function filmAmortized(ctx: ConditionContext, payload: FilmAmortizedCondition) {
  const { state } = ctx;
  const { target, operator } = payload;
  if (isNumber(target)) throw new Error('FilmAmortized condition should have a target with a reference');
  const amortization = state.amortizations[target.id];
  const currentValue = (state.pools[amortization.poolId]?.turnover.calculated ?? 0) + amortization.financing;
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, currentValue, targetValue);
}

export interface RightCondition {
  rightId: string;
  target: TargetValue;
  operator: ThresholdNumberOperator;
}

function rightRevenu(ctx: ConditionContext, payload: RightCondition) {
  const { state } = ctx;
  const { rightId, target, operator } = payload;
  assertNode(state, rightId);
  const right = getNode(state, rightId);
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, right.revenu.calculated, targetValue);
}

export interface TakeFromUntilCondition {
  rightId: string;
  sourceId: string;
  percent: number;
}
function takefromIncomeUntil(ctx: ConditionContext, payload: TakeFromUntilCondition) {
  const { state } = ctx;
  const { rightId, percent, sourceId } = payload;
  if (ctx.sourceId !== sourceId) return false;
  assertNode(state, rightId);
  const right = getNode(state, rightId);
  if (!isRight(state, right)) throw new Error(`Node "${rightId}" is not a right`);
  const incomeIds = state.sources[sourceId].incomeIds;
  const incomeId = incomeIds.length ? incomeIds[incomeIds.length - 1] : undefined;
  const targetValue = incomeId ? state.incomes[incomeId].amount * percent : 0;
  const current = incomeId && right.revenu.history[incomeId] ? right.revenu.history[incomeId] : 0;
  return thresholdOperator('<', current ?? 0, targetValue);
}

function takefromSourceUntil(ctx: ConditionContext, payload: TakeFromUntilCondition) {
  const { state } = ctx;
  const { rightId, percent, sourceId } = payload;
  if (ctx.sourceId !== sourceId) return false;
  assertNode(state, rightId);
  const right = getNode(state, rightId);
  const targetValue = state.sources[sourceId].amount * percent;
  return thresholdOperator('<', right.revenu.calculated, targetValue);
}

function rightTurnover(ctx: ConditionContext, payload: RightCondition) {
  const { state } = ctx;
  const { rightId, target, operator } = payload;
  assertNode(state, rightId);
  const right = getNode(state, rightId);
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, right.turnover.calculated, targetValue);
}

export interface GroupCondition {
  groupId: string;
  target: TargetValue;
  operator: ThresholdNumberOperator;
}
function groupRevenu(ctx: ConditionContext, payload: GroupCondition) {
  const { state } = ctx;
  const { groupId, target, operator } = payload;
  assertNode(state, groupId);
  const right = getNode(state, groupId);
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, right.revenu.calculated, targetValue);
}
function groupTurnover(ctx: ConditionContext, payload: GroupCondition) {
  const { state } = ctx;
  const { groupId, target, operator } = payload;
  assertNode(state, groupId);
  const right = getNode(state, groupId);
  const targetValue = toTargetValue(state, target);
  return thresholdOperator(operator, right.turnover.calculated, targetValue);
}

///////////
// EVENT //
///////////

export interface EventCondition {
  eventId: string;
  operator?: BlockingNumberOperator;
  target?: number;
}

function event(ctx: ConditionContext, payload: EventCondition) {
  const { state } = ctx;
  const { eventId, target, operator } = payload;

  const event = state.events[eventId];
  if (event === undefined) return false; // Event did not happen yet
  if (!operator || event === true) return true; // We only check if event happened

  return blockingOperator(operator, event, target);
}

/**
 * Return Waterfall Events linked to a right by its conditions
 * @param right 
 * @param state
 * @param filterFullfiled
 * @returns 
 */
export function getEvents(right: Right, state: TitleState, filterFullfiled = false) {
  const conditions = getRightConditions(right);
  const eventConditions = conditions.filter(c => c.name === 'event').map(c => c.payload as EventCondition);
  const waterfallEvents = eventConditions.map(payload => {
    const mode: WaterfallEventMode = payload.operator ? 'number' : 'boolean';
    if (mode === 'number') {
      const stateValue = (state.events[payload.eventId] as number) || 0;
      return createWaterfallEvent({ id: payload.eventId, amount: 0, target: payload.target, prior: stateValue });
    } else if (mode === 'boolean') {
      const stateValue = !!state.events[payload.eventId];
      const isConditionFullfilled = stateValue;
      if (!filterFullfiled || !isConditionFullfilled) {
        return createWaterfallEvent({ id: payload.eventId, checked: stateValue });
      }
    }
  }).filter(e => !!e);

  const eventIds = Array.from(new Set(waterfallEvents.map(e => e.id)));
  return eventIds.map(id => {
    const events = waterfallEvents.filter(e => e.id === id);
    if (events.length > 1) {
      // If there is multiple condition with same event, return event that have highest target
      const max = Math.max(...events.map(e => e.target ?? 0));
      return events.find(e => e.target === max);
    } else {
      return events[0];
    }
  });
}

//////////////
// DURATION //
//////////////

export interface ConditionDuration {
  from?: Date;
  to?: Date;
}
function incomeDate(ctx: ConditionContext, payload: ConditionDuration) {
  const { income } = ctx;
  const { from, to } = payload;
  const date = income.date;
  if (!date) throw new Error(`Income "${income.id}" do not have a date.`);
  if (from && to) return (date >= from && date <= to);
  if (from) return date >= from;
  if (to) return date <= to;
  throw new Error('Income date condition should have either from or/and to. But none were provided');
}

function contractDate(ctx: ConditionContext, payload: ConditionDuration) {
  const { income, right } = ctx;
  const { from, to } = payload;
  const contractId = income.contractId;
  if (!contractId) throw new Error(`Right "${right.id}" have a condition on contract date, but income "${income.id}" do not provide a contractId`);
  if (!ctx.state.contracts[contractId]) throw new Error(`Contract "${contractId}" not found in state`);

  const { start, end } = ctx.state.contracts[contractId];
  if (!start || !end) throw new Error(`Contract "${contractId}" does not specify valid start and end dates`);

  if (from && to) return (start >= from && end <= to);
  if (from) return start >= from;
  if (to) return end <= to;

  throw new Error('Income date condition should have either from or/and to. But none were provided');
}

function rightEnabled(ctx: ConditionContext) {
  const { income, right, state } = ctx;
  const contractId = right.contractId;
  const contract = state.contracts[contractId];
  if (!contract) {
    // This happens when a contract signature date is greater than income date: the contract is not yet in state
    return false;
  }
  const date = income.date;
  if (!date) throw new Error(`Income "${income.id}" do not have a date.`);
  if (!contract.start || !contract.end) throw new Error(`Contract "${contractId}" does not specify valid start and end dates`);
  return (date >= contract.start && date <= contract.end);
}

////////////////////////////
// INCOME & SOURCE AMOUNT //
////////////////////////////

export interface ConditionAmount {
  operator: BlockingNumberOperator;
  target: number;
}
function amount(ctx: ConditionContext, payload: ConditionAmount) {
  const { income } = ctx;
  const { operator, target } = payload;
  return blockingOperator(operator, income.amount, target);
}

export interface SourceAmountCondition {
  sourceId: string;
  target: TargetValue;
  operator: BlockingNumberOperator;
}
function sourceAmount(ctx: ConditionContext, payload: SourceAmountCondition) {
  const { state } = ctx;
  const source = state.sources[payload.sourceId];
  const amount = source?.amount ?? 0;
  const { operator, target } = payload;
  const targetValue = toTargetValue(state, target);
  return blockingOperator(operator, amount, targetValue);
}

export interface ConditionTerms {
  operator: BlockingArrayOperator;
  type: GroupScope;
  list: Media[] | Territory[];
}
function terms(ctx: ConditionContext, payload: ConditionTerms) {
  const { income } = ctx;
  const { operator, type, list } = payload;
  const hasItem = income[type]?.some((item: (Media | Territory)) => (list as (Media | Territory)[]).includes(item));
  if (operator === 'in') return hasItem;
  if (operator === 'not-in') return !hasItem;
  throw new Error('Terms condition should have at least the operators "in" or "not-in"');
}

interface ConditionTermsLength {
  operator: BlockingNumberOperator;
  type: GroupScope;
  target: number;
}
function termsLength(ctx: ConditionContext, payload: ConditionTermsLength) {
  const { income } = ctx;
  const { operator, type, target } = payload;
  const terms = income[type];
  return blockingOperator(operator, terms.length, target);
}

interface ConditionContract {
  operator: BlockingArrayOperator;
  contractIds: string[];
}
/** Check if the income is or not from one of the contractId */
function contract(ctx: ConditionContext, payload: ConditionContract) {
  const { income } = ctx;
  const { operator, contractIds } = payload;
  const hasItem = contractIds.includes(income.contractId ?? '__');
  if (operator === 'in') return hasItem;
  if (operator === 'not-in') return !hasItem;
  throw new Error('Terms condition should have at least the operators "in" or "not-in"');
}

export interface ConditionContractAmount {
  operator: BlockingNumberOperator;
  target: number;
}
function contractAmount(ctx: ConditionContext, payload: ConditionContractAmount) {
  const { income, right, state } = ctx;
  const { operator, target } = payload;
  const contractId = income.contractId;
  if (!contractId) throw new Error(`Right "${right.id}" have a condition on contract amount, but income "${income.id}" do not provide a contractId`);
  const current = ctx.state.contracts[contractId]?.amount;
  const targetValue = toTargetValue(state, target);
  return blockingOperator(operator, current, targetValue);
}

//////////////
// INTEREST //
//////////////

export interface ConditionInterest {
  orgId: string;
  contractId: string;
  operator: ThresholdNumberOperator;
  percent: number;
  rate: number;
  isComposite?: boolean;
}
/**
 * @param ctx 
 * @param payload 
 * @returns 
 */
function interest(ctx: ConditionContext, payload: ConditionInterest) {
  const { state } = ctx;
  const { orgId, contractId, operator, percent, rate, isComposite } = payload;
  const { revenu, operations } = state.orgs[orgId];
  const contractOperations = operations.filter(o => o.type === 'income' || (o.type === 'investment' && o.contractId === contractId));
  const targetValue = investmentWithInterest(rate, contractOperations, isComposite) * percent;
  const current = revenu.calculated;
  return thresholdOperator(operator, current, targetValue);
}