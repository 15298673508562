import { DocumentMeta } from '../meta';
import {
  PaymentStatus,
  PaymentType,
  StatementType,
  StatementStatus,
  rightholderGroups,
  statementsRolesMapping,
  NegotiationStatus,
  RightType,
  Media,
  Territory,
  StatementMode
} from '../static';
import {
  Version,
  WaterfallContract,
  WaterfallContractPriceReference,
  WaterfallEvent,
  WaterfallRightholder,
  WaterfallSource,
  createWaterfallEvent,
  dealTypes,
  getIncomesSources
} from './waterfall';
import { Duration, createDuration } from '../terms';
import { sortByDate, sum } from '../utils';
import { TitleState, TransferState } from './state';
import { PercentConfig, Right, RightOverride, createRightOverride, getChilds, getRightExpenseTypes, skipGroups } from './right';
import { pathExists } from './node';
import { Income, createIncome, getMatchingIncomes } from '../income';
import { Expense } from '../expense';
import { InterestDetail } from './interest';
import { AmortizationDetails } from './amortization';
import { TargetIn } from './conditions';

export const toFixed = (number: number, p = 100) => Math.round(number * p) / p;

export const areEqual = (a: number, b: number, epsilon = 0.00001) => Math.abs(a - b) < epsilon;

// -----------------
// Interfaces
// -----------------

export interface Payment {
  id: string;
  type: PaymentType;
  price: number;
  date?: Date;
  status: PaymentStatus;
  mode: 'internal' | 'external';
}

/**
 * "To Income" payment to the rightholder's org of the statement, will increment org actual revenu and turnover
 */
export interface IncomePayment extends Payment {
  type: 'income';
  incomeId: string; // Income related to this payment
  status: 'received';
  mode: 'internal';
}

/**
 * "To Rightholder" payment to the org of the opposite rightholder of the statement (licensor or licensee)
 * Once received, will take money available in Org.revenu.actual and assign it to the other Org, incrementing org actual revenu and turnover
 */
export interface RightholderPayment extends Payment {
  type: 'rightholder';
  mode: 'external';
  incomeIds: string[]; // Incomes related to this payment
}

/**
 * "To Right" payment (com, expense, MG etc)
 * Once received, will assign money available in Org.revenu.actual to correct rights, incrementing right actual revenu and turnover
 */
export interface RightPayment extends Payment {
  type: 'right';
  mode: 'internal' | 'external';
  incomeIds: string[]; // Incomes related to this payment
  to: string; // rightId
  shadow: number; // Current shadow revenu for this right
}

export interface Statement {
  _meta?: DocumentMeta;
  type: StatementType;
  mode: StatementMode;
  contractId?: string;
  status: StatementStatus;
  reviewStatus?: NegotiationStatus;
  id: string;
  waterfallId: string;
  senderId: string, // rightholderId of statement creator
  receiverId: string, // rightholderId of statement receiver
  duration: Duration;
  reported?: Date;
  incomeIds: string[];
  expenseIds?: string[];
  versionId: string; // Version used to create this statement. Except for standalone versions, this id is set when statement is reported
  duplicatedFrom: string; // Id of the statement this one was duplicated from
  standalone: boolean; // True if statement was generated using a standalone waterfall version
  payments: {
    income?: IncomePayment[];
    right: RightPayment[]
    rightholder?: RightholderPayment;
  };
  events?: WaterfallEvent[], // Declared events only for Distributor and Direct Sales statements
  comment: string;
  rightOverrides: RightOverride[];
  reportedData: { // Final data of the statement once it is reported
    // For Distributor and Direct Sales statements.
    sourcesBreakdown?: SourcesBreakdown[];
    rightsBreakdown?: RightsBreakdown[];
    expenses?: Expense[]; // Expenses history TODO #9909 rework
    distributorExpenses?: DistributorExpenses[]; // Expenses details TODO #9909 rework
    producerNetParticipation?: number; // Current Net Profit (for direct sales statements only)

    // For Outgoing statements
    outgoingBreakdown?: OutgoingBreakdown[];
    outgoingDetails?: OutgoingDetails[]; // Rights details TODO #9909 rework vue to still be able to edit incomes
    expensesPerDistributor?: Record<string, (Expense & { cap?: number, editable: boolean })[]>; // Expenses history TODO #9909 rework vue to still be able to edit incomes
    distributorExpensesPerDistributor?: Record<string, DistributorExpenses[]>; // Expenses details TODO #9909 rework vue to still be able to edit incomes
    interests?: InterestDetail[]; // Interest details
    amortization?: AmortizationDetails; // Amortization details for outgoing statements
    dsmBreakdown?: DsmBreakdown[];

    // For All kinds of statements
    waterfallEvents?: WaterfallEvent[]; // Events declared on distributor statements, also copied to Outgoing statements
    recoupments?: RecoupmentsBreakdown[];
  },
  hash: {
    requested: boolean;
    requestDate?: Date;
    requestedBy?: string; // User uid
    hash?: string;
  }
}

export interface StatementWithNumber extends Statement {
  number: number;
}

export interface DistributorStatement extends Statement {
  type: 'mainDistributor' | 'salesAgent';
  contractId: string; // For distributor statements, rightholderId is licensee (and producer is licensor) of this contract
  expenseIds: string[];
  payments: {
    income: IncomePayment[];
    right: RightPayment[]; // Mode internal & external
    rightholder: RightholderPayment;
  };
  additional: {
    quantitySold?: number;
    salesContractIds?: {
      incomeId: string;
      contractId: string;
    }[];
  },
  events: WaterfallEvent[], // Declared events
}

export interface ProducerStatement extends Statement {
  type: 'producer';
  contractId: string; // For outgoing statements, rightholderId (producer) is licensor of this contract, except for statements made to author (in this case, producer is licensee)
  payments: {
    right: RightPayment[]; // Mode external
    rightholder: RightholderPayment;
  };
}

export interface DirectSalesStatement extends Statement {
  type: 'directSales';
  expenseIds: string[];
  payments: {
    income: IncomePayment[];
    right: RightPayment[]; // Mode internal
  };
  events: WaterfallEvent[], // Declared events
}

export interface MaxPerIncome {
  income: Income;
  max: number;
  current: number;
  source: WaterfallSource
}

export type BreakdownRowType =
  'right' |
  'net' |
  'source' |
  'total' |
  'income' |
  'grossReceipts' |
  'extra' |
  'ocurred-event' |
  'recoupment' |
  'outstanding' |
  'vertical' |
  'recoupment-vertical' |
  'right-vertical' |
  'recoupment-total' |
  'original-amount' |
  'outgoing-vertical-right' |
  'outgoing-right';
export interface BreakdownRow {
  section: string;
  type?: BreakdownRowType;
  previous: number;
  current: number;
  cumulated: number;
  right?: Right;
  percentConfig?: PercentConfig;
  cap?: number;
  source?: WaterfallSource;
  maxPerIncome?: MaxPerIncome[];
  medias?: Media[];
  territories?: Territory[];
  licensee?: string;
  contractPrice?: WaterfallContractPriceReference;
  css?: string; // Custom css for table row
  rowStyle?: 'even' | 'odd';
}

export interface SourcesBreakdown {
  name: string;
  id: string;
  rows: BreakdownRow[];
  net: number;
}

export interface DsmBreakdown {
  name: string;
  id: string;
  rows: BreakdownRow[];
  total: number;
}

export interface RightsBreakdown {
  name: string;
  rows: BreakdownRow[];
  total: number;
}

export interface RecoupmentsBreakdown {
  name: string;
  target: TargetIn | 'number';
  rows: BreakdownRow[];
  current: number;
  key?: string;
}

export interface OutgoingBreakdown {
  group: Right;
  rights: Right[];
  rows: BreakdownRow[];
  net: number;
}

export interface OutgoingDetails {
  name: string;
  net: number;
  details: {
    node: string;
    sourceId?: string;
    previous: number;
    current: number;
    cumulated: number;
  }[]
}

export interface DistributorExpenses {
  name: string;
  rows: {
    capped: boolean;
    previous: number;
    current: number,
    cumulated: number
  }[]
}

// -----------------
// Creation functions
// -----------------

function createPaymentBase(params: Partial<Payment> = {}): Payment {
  return {
    id: '',
    type: 'rightholder',
    price: 0,
    status: 'pending',
    mode: 'internal',
    ...params,
  };
}

export function createIncomePayment(params: Partial<IncomePayment> = {}): IncomePayment {
  const payment = createPaymentBase(params);
  return {
    incomeId: '',
    ...payment,
    type: 'income',
    status: 'received',
    mode: 'internal',
  }
}

export function createRightholderPayment(params: Partial<RightholderPayment> = {}): RightholderPayment {
  const payment = createPaymentBase(params);
  return {
    ...payment,
    incomeIds: params.incomeIds || [],
    mode: 'external',
    type: 'rightholder',
  }
}

export function createRightPayment(params: Partial<RightPayment> = {}): RightPayment {
  const payment = createPaymentBase(params);
  return {
    to: '',
    shadow: 0,
    ...payment,
    incomeIds: params.incomeIds || [],
    type: 'right',
    mode: params.mode
  }
}

function createStatementBase(params: Partial<Statement> = {}): Statement {
  return {
    id: '',
    type: 'producer',
    mode: 'default',
    status: 'draft',
    waterfallId: '',
    senderId: '',
    receiverId: '',
    incomeIds: params.incomeIds || [],
    versionId: '',
    duplicatedFrom: '',
    standalone: false,
    payments: {
      right: params.payments?.right ? params.payments.right.map(createRightPayment) : []
    },
    comment: params.comment || '',
    reportedData: {},
    hash: { requested: false },
    ...params,
    duration: createDuration(params?.duration),
    rightOverrides: params.rightOverrides ? params.rightOverrides.map(createRightOverride) : []
  };
}

export function createStatement(params: Partial<Statement>) {
  if (isDistributorStatement(params)) return createDistributorStatement(params);
  if (isProducerStatement(params)) return createProducerStatement(params);
  if (isDirectSalesStatement(params)) return createDirectSalesStatement(params);
}

export function isDistributorStatement(statement: Partial<Statement>): statement is DistributorStatement {
  return Object.keys(rightholderGroups.distributors).includes(statement.type);
}

export function isProducerStatement(statement: Partial<Statement>): statement is ProducerStatement {
  return statement.type === 'producer';
}

export function isDirectSalesStatement(statement: Partial<Statement>): statement is DirectSalesStatement {
  return statement.type === 'directSales';
}

export function createDistributorStatement(params: Partial<DistributorStatement> = {}): DistributorStatement {
  const statement = createStatementBase(params);
  return {
    contractId: '',
    ...statement,
    payments: {
      income: params.payments?.income ? params.payments.income.map(createIncomePayment) : [],
      right: statement.payments.right,
      rightholder: params.payments?.rightholder || undefined
    },
    type: params.type || 'mainDistributor',
    expenseIds: params.expenseIds || [],
    additional: {},
    events: params.events?.length ? params.events.map(createWaterfallEvent) : []
  }
}

export function createProducerStatement(params: Partial<ProducerStatement> = {}): ProducerStatement {
  const statement = createStatementBase(params);
  return {
    contractId: '',
    ...statement,
    payments: {
      right: statement.payments.right,
      rightholder: params.payments?.rightholder || undefined
    },
    type: 'producer',
  }
}

export function createDirectSalesStatement(params: Partial<DirectSalesStatement> = {}): DirectSalesStatement {
  const statement = createStatementBase(params);
  return {
    ...statement,
    payments: {
      income: params.payments?.income ? params.payments.income.map(createIncomePayment) : [],
      right: statement.payments.right
    },
    type: 'directSales',
    expenseIds: params.expenseIds || [],
    events: params.events?.length ? params.events.map(createWaterfallEvent) : []
  }
}

// -----------------
// Utility functions
// -----------------

/**
 * Return the key used to identify the rightholder in the statement that is not the producer
 * @param type 
 * @returns 
 */
export function rightholderKey(type: StatementType) {
  return type === 'producer' ? 'receiverId' : 'senderId';
}

export function filterStatements(type: StatementType, mode: StatementMode = 'default', parties: string[], contractId = '', statements: Statement[]) {
  if (parties.length !== 2) return [];
  const isSender = (s: Statement) => s.senderId === parties[0] && s.receiverId === parties[1];
  const isReceiver = (s: Statement) => s.senderId === parties[1] && s.receiverId === parties[0];
  const filteredStatements = statements
    .filter(s => s.type === type && (isSender(s)) || isReceiver(s))
    .filter(s => (!s.mode && mode === 'default') || s.mode === mode); /** @dev if s.mode is not set, consider it as default (backward compatibility) **/
  if (type !== 'directSales') return filteredStatements.filter(s => s.contractId === contractId);
  return filteredStatements;
}

/**
 * Filter statements that are related to the rightholder (sender or receiver) 
 * and also the parent statements used to generate the rightholder statements (if any).
 * @param statements 
 * @param rightholderId 
 * @returns 
 */
export function filterRightholderStatements(_statements: Statement[], rightholder: WaterfallRightholder) {
  const isDistributor = rightholder.roles.some(role => statementsRolesMapping.mainDistributor.includes(role));
  const isSalesAgent = rightholder.roles.some(role => statementsRolesMapping.salesAgent.includes(role));
  const isDirectSales = rightholder.roles.some(role => statementsRolesMapping.directSales.includes(role));

  const statements = (isDistributor || isSalesAgent || isDirectSales) ? _statements : _statements.filter(s => s.status === 'reported');

  const rightholderStatements = statements.filter(s => [s.senderId, s.receiverId].includes(rightholder.id));
  const rightholderStatementsIds = rightholderStatements.map(s => s.id);
  const incomeIds = rightholderStatements.map(s => s.incomeIds).flat();
  const parentStatements = statements.filter(s => !rightholderStatementsIds.includes(s.id) && !isProducerStatement(s) && s.incomeIds.some(id => incomeIds.includes(id)));
  return [...rightholderStatements, ...parentStatements];
}

export function sortStatements<T extends Statement>(statements: T[], reverse = true): (T & { number: number })[] {
  const sortedStatements = sortByDate(statements, 'duration.to').map((s, i) => ({ ...s, number: i + 1 }));
  return reverse ? sortedStatements.reverse() : sortedStatements;
}

export function getStatementNumber(current: Statement, statements: Statement[]) {
  const filteredStatements = filterStatements(current.type, current.mode, [current.senderId, current.receiverId], current.contractId, statements);
  const history = sortStatements(filteredStatements);
  return history.find(s => s.id === current.id)?.number || 1;
}

/**
 * Return statement history of current statement
 * @param current 
 * @param statements 
 * @param onlyPrevious 
 * @returns 
 */
export function statementHistory(current: Statement, statements: Statement[], onlyPrevious = false) {
  const filteredStatements = filterStatements(current.type, current.mode, [current.senderId, current.receiverId], current.contractId, statements);
  const sortedStatements = sortStatements(filteredStatements);
  if (onlyPrevious) {
    const number = sortedStatements.find(s => s.id === current.id)?.number;
    return sortedStatements.filter(s => s.number < number);
  } else {
    return sortedStatements;
  }
}

/**
 * Return statement history of current statement with its parents:
 * history is current statement history and parent statements history
 * @param current 
 * @param _statements 
 * @returns 
 */
export function statementHistoryWithParents(current: Statement, _statements: Statement[]) {
  const parentStatements = getParentStatements(_statements, current.incomeIds);
  const statements = [...statementHistory(current, _statements)];
  for (const parent of parentStatements) {
    const parentHistory = statementHistory(parent, _statements);
    for (const h of parentHistory) {
      if (!statements.find(s => s.id === h.id)) statements.push(h);
    }
  }
  return sortStatements(statements);
}

/**
 * Return income history of current statement
 * @param statement 
 * @param statements 
 * @param incomes 
 * @returns 
 */
export function incomeHistory(statement: Statement, statements: Statement[], incomes: Income[]) {
  const history = statementHistory(statement, statements, true);
  const uniqueIds = Array.from(new Set(history.map(s => s.incomeIds).flat()));
  return incomes.filter(i => uniqueIds.includes(i.id));
}

/**
 * Return the rights that should be used during statement creation
 * Will include rights of senderId and/or receiverId depending of the statement type.
 * If statement have a contractId, it will also be used to filter rights.
 * @param statement 
 * @param _rights 
 * @returns 
 */
export function getStatementRights(statement: Statement, _rights: Right[]) {
  const rights = skipGroups(_rights);

  if (isDistributorStatement(statement)) {
    return rights.filter(r => r.rightholderId === statement.receiverId || r.contractId === statement.contractId);
  } else if (isProducerStatement(statement)) {
    return rights.filter(r => r.rightholderId !== statement.senderId && r.contractId === statement.contractId);
  } else if (isDirectSalesStatement(statement)) {
    return rights.filter(r => r.rightholderId === statement.senderId);
  }
}

/**
 * Return the rights that should be displayed on the statement view
 * @param statement 
 * @param _rights 
 * @returns 
 */
export function getStatementRightsToDisplay(statement: Statement, _rights: Right[]) {
  const rights = getStatementRights(statement, _rights);
  if (isDistributorStatement(statement) || isDirectSalesStatement(statement)) return rights.filter(r => r.rightholderId === statement.senderId);
  if (isProducerStatement(statement)) return rights;
}

/**
 * Return a subset of rights ordered by their position between each others
 * @param rights 
 * @param state 
 * @param statement
 * @returns 
 */
export function getOrderedRights(rights: Right[], state: TitleState, statement?: Statement) {
  const displayedRightTypes: RightType[] = !!statement && isDirectSalesStatement(statement) ? ['commission', 'expenses'] : [];
  if (rights.length === 1) {
    return rights.filter(right => displayedRightTypes.length ? displayedRightTypes.includes(right.type) : true);
  }
  const firstStep = rights.sort((a, b) => pathExists(a.id, b.id, state) ? 1 : -1);

  // On direct sales statements, only display commission and expenses rights
  // This is needed to show only theses kind of rights since directSales statements does not have a contract Id.
  let secondStep = firstStep.filter(right => displayedRightTypes.length ? displayedRightTypes.includes(right.type) : true);

  // Sort group members together
  const thirdStep: Right[] = [];
  for (const right of secondStep) {
    if (right.groupId) {
      const brothers = secondStep.filter(r => r.groupId === right.groupId).sort((a, b) => a.order - b.order);
      thirdStep.push(...brothers);
      secondStep = secondStep.filter(r => r.groupId !== right.groupId);
      for (const brother of brothers) {
        if (brother.type === 'vertical') {
          const subBrothers = secondStep.filter(r => r.groupId === brother.id).sort((a, b) => a.order - b.order);
          thirdStep.push(...subBrothers);
          secondStep = secondStep.filter(r => r.groupId !== brother.id);
        }
      }
    } else {
      thirdStep.push(right);
    }
  }

  return thirdStep;
}

/**
 * Look into transfer state to find the history transfers for this rightId and incomeIds
 * @param rightId 
 * @param _incomeIds 
 * @param transferState 
 */
function getTransfersHistory(rightId: string, _incomeIds: string[] | string, transferState: Record<string, TransferState>, options: { checked: boolean } = { checked: true }) {
  const incomeIds = Array.isArray(_incomeIds) ? _incomeIds : [_incomeIds];
  const transfers = Object.values(transferState).filter(t => t.to === rightId);
  const history = transfers.map(t => t.history.filter(h => incomeIds.includes(h.incomeId))).flat();
  return options.checked ? history.filter(h => h.checked) : history;
}

/**
 * Look into transfer state to find the transfered amount (turnover) to this rightId for this incomeIds
 * @param rightId 
 * @param _incomeIds 
 * @param transferState 
 * @returns number
 */
export function getIncomingAmount(rightId: string, incomeIds: string[] | string, transferState: Record<string, TransferState>): number {
  const history = getTransfersHistory(rightId, incomeIds, transferState, { checked: false });
  return toFixed(sum(history, i => i.amount));
}

/**
 * Look into transfer state to find the calculated amount for this rightId and incomeIds
 * @param rightId 
 * @param _incomeIds 
 * @param transferState 
 * @returns number
 */
export function getCalculatedAmount(rightId: string, incomeIds: string[] | string, transferState: Record<string, TransferState>, options?: { rounded: boolean }): number {
  const history = getTransfersHistory(rightId, incomeIds, transferState);
  const amount = sum(history, i => i.amount * i.percent);
  return options?.rounded ? toFixed(amount) : amount;
}

export function hasRightsWithExpenseCondition(_rights: Right[], senderId: string) {
  const rights = _rights.filter(r => r.rightholderId === senderId);
  return rights.some(r => getRightExpenseTypes(r).length > 0);
}

export function convertStatementsTo(_statements: Statement[], version: Version) {
  if (!version?.id) return _statements;
  if (version.standalone) return _statements.filter(s => s.versionId === version.id);
  const statements = _statements.filter(s => !s.standalone);
  const duplicatedStatements = statements.filter(s => !!s.duplicatedFrom);
  const rootStatements = statements.filter(s => !s.duplicatedFrom);
  return rootStatements.map(s => duplicatedStatements.find(d => d.duplicatedFrom === s.id && d.versionId === version.id) || s);
}

export function getParentStatements(statements: Statement[], incomeIds: string[], skipDuplicates = false) {
  return statements.filter(s => isDirectSalesStatement(s) || isDistributorStatement(s))
    .filter(s => skipDuplicates ? !s.duplicatedFrom : true) // Skip already duplicated statements
    .filter(s => s.payments.right.some(r => r.incomeIds.some(id => incomeIds.includes(id))));
}

export function getNonEditableNodeIds(rights: Right[], sources: WaterfallSource[], reportedStatements: Statement[], incomes: Income[]) {
  const incomeIds = Array.from(new Set(reportedStatements.map(s => s.incomeIds).flat()));
  const reportedIncomes = incomeIds.map(id => incomes.find(i => i.id === id)).filter(i => !!i);
  const nonEditableSources = reportedIncomes ? getIncomesSources(reportedIncomes, sources).filter(s => !!s) : [];
  const topLevelRights = nonEditableSources.map(s => rights.find(r => r.id === s.destinationId)).filter(r => !!r);
  const childIds = Array.from(new Set(topLevelRights.map(r => getChilds(r.id, rights).map(c => c.id)).flat()));
  const nonEditableRights = childIds.map(id => rights.find(r => r.id === id));
  const groupIds = Array.from(new Set(nonEditableRights.filter(r => r.groupId).map(r => r.groupId)));
  const nonEditableGroups = groupIds.map(id => rights.find(r => r.id === id));
  const parentGroupIds = Array.from(new Set(nonEditableGroups.filter(r => r.groupId).map(r => r.groupId))).filter(i => !!i);
  return [...nonEditableSources.map(s => s.id), ...nonEditableRights.map(r => r.id), ...nonEditableGroups.map(g => g.id), ...parentGroupIds];
}

interface BreakdownRowWithActions extends BreakdownRow {
  actions?: boolean;
  preventDelete?: boolean;
  index?: number;
  contractId?: string;
}
export function incomeHistoryWithExtra(incomes: Income[], history: Income[], contracts: WaterfallContract[]) {

  const data: BreakdownRowWithActions[] = [];
  let index = 0;
  for (const income of incomes) {

    // Find previous incomes with same contractId, media and territories
    const matchingIncomes = getMatchingIncomes(income, history);
    const previous = matchingIncomes.reduce((acc, m) => acc + m.price, 0);

    const row: BreakdownRowWithActions = {
      section: income.id,
      index: index++,
      contractId: income.contractId,
      actions: true,
      preventDelete: matchingIncomes.length !== 0,
      medias: income.medias,
      territories: income.territories,
      type: 'income',
      previous,
      current: income.price,
      cumulated: income.price + previous,
      licensee: income.licensee
    };

    const deal = contracts.find(d => d.id === income.contractId);
    if (deal && dealTypes.includes(deal.type)) {
      const price = deal.price;
      if (price.reference?.value > 0) {
        row.contractPrice = { currency: price.reference.currency, value: price.reference.value };
      } else {
        row.contractPrice = { value: price.value || 0 };
      }
    }

    data.push(row);

    for (const key of Object.keys(income.extra)) {
      const previousExtra = matchingIncomes.reduce((acc, m) => acc + (m.extra && m.extra[key] ? m.extra[key] : 0), 0);
      data.push({
        section: '',
        type: 'extra',
        index: row.index,
        medias: [],
        territories: [],
        previous: previousExtra,
        current: income.extra[key],
        cumulated: income.extra[key] + previousExtra,
        licensee: key
      });
    }
  }
  return data;
}

export function findRelatedStatements(statement: Statement, statements: Statement[]) {

  if (statement.status === 'draft') {
    const current = statements.find(s => s.id === statement.id);
    return [{ ...current, number: getStatementNumber(current, statements) }];
  }
  const notDrafts = statements.filter(s => s.status !== 'draft');

  const duplicates = notDrafts.filter(s => s.duplicatedFrom === statement.id);
  const childs = isProducerStatement(statement) ? [] : notDrafts.filter(s => statement.incomeIds.some(id => s.incomeIds.includes(id)));
  const childsOfDuplicates = duplicates.map(r => notDrafts.filter(s => r.incomeIds.some(id => s.incomeIds.includes(id)))).flat();
  const next = isProducerStatement ?
    notDrafts.filter(s => s.duration.to.getTime() > statement.duration.to.getTime()) :
    notDrafts.filter(s => s.duration.to.getTime() >= statement.duration.to.getTime());

  const ids = [...duplicates, ...childs, ...childsOfDuplicates, ...next].map(s => s.id);
  ids.push(statement.id);
  const uniqueIds = Array.from(new Set(ids));
  return notDrafts.filter(s => uniqueIds.includes(s.id)).map(s => ({ ...s, number: getStatementNumber(s, statements) }));
}

export function mergeMatchingIncomes(_incomes: Income[]) {
  const incomes: Income[] = [];
  const ids: string[] = [];
  for (const _i of _incomes) {
    const i = createIncome({ ..._i, extra: { ..._i.extra } });
    if (ids.includes(i.id)) continue;
    ids.push(i.id);
    const matchingIncomes = getMatchingIncomes(i, _incomes.filter(h => h.id !== i.id));
    ids.push(...matchingIncomes.map(m => m.id));

    i.price = i.price + matchingIncomes.reduce((acc, m) => acc + m.price, 0);

    for (const matchingIncome of matchingIncomes) {
      for (const extra of Object.keys(matchingIncome.extra)) {
        if (!i.extra[extra]) i.extra[extra] = 0;
        i.extra[extra] = i.extra[extra] + matchingIncome.extra[extra];
      }
    }

    incomes.push(i);
  }
  return incomes;
}